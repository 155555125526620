import React from 'react';

const Activity = () => {
  return (
    <div>
      Activity
    </div>
  );
};

export default Activity;