import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Activity from './pages/Activity';
import Dashboard from './pages/Dashboard';
import Task from './pages/Task';
import Layout from './Layout';


const Routers = () => {
  return (


    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="activity" element={<Activity />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="task" element={<Task />} />

        </Route>
      </Routes>
    </Router>
  );
};

export default Routers;