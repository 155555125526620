import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SettingOutlined,
  SearchOutlined,
  UserOutlined,
  DownSquareOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Input, Dropdown } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';

type MenuItem = Required<MenuProps>['items'][number];

type ItemProps = {
  name: string;
  alert?: string;
  link?: string;
};

const Item: React.FC<ItemProps> = ({ name, alert, link }) => {
  return (
    <div className='flex justify-between content-end text-primary'>
      {link ?
        <Link to={link}>{name}</Link> : <span>{name}</span>
      }
      {alert && (
        <div className='items-end h-max w-10'>
          <span className='border px-2 py-1 rounded-md items-end'>{alert}</span>
        </div>
      )}
    </div>
  );
};

const items: MenuItem[] = [
  { key: '1', icon: <PieChartOutlined />, label: <Item name='Dashboard' /> },
  { key: '2', icon: <DesktopOutlined />, label: <Item name="Activity" alert='10' /> },
  { key: '3', icon: <ContainerOutlined />, label: <Item name='My tasks' alert='3' /> },
];


const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const menu = ["Dashboard", "Activity", "My tasks"]
  const [search, setSearch] = useState(menu);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase();
    if (!inputValue) {
      setSearch(menu);
    } else {
      const filteredMenu = menu
        .filter((item) => item.toLowerCase().includes(inputValue));
      setSearch(filteredMenu);
    }
  };

  return (
    <aside className='absolute h-screen top-0 bg-gray-50 scroll-smooth overflow-auto scrollbar-thin scrollbar-custom '>
      <nav id='sid' className={`p-3 h-full flex flex-col shadow-sm transition-all`} style={{ width: `${!collapsed ? '256px' : '100px'}`, }}>
        <div className=' m-3 p4 pb-2 flex justify-between items-center'>
          <img src='/logo192.png' className={`overflow-hidden transition-all ${!collapsed ? 'w-8  ' : 'w-0'} `} />
          <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }} className='bg-primary hover:bg-secondary'>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        <ul className='flex-1'>
          <li>
            <Input
              className={`w-10/12 mx-auto mt-2 mb-2 bg-transparent placeholder-white-gray focus:bg-transparent text-gray-700 ${collapsed ? 'hidden' : ''}`}
              prefix={<SearchOutlined />}
              placeholder='Search'
              onChange={handleChange}
            />
          </li>
          <li>
            <SearchOutlined className={`${!collapsed ? 'hidden' : ''} text-gray-700`} />
          </li>
          <li>
            <Menu
              defaultSelectedKeys={['1']}
              mode="inline"
              theme='light'
              inlineCollapsed={collapsed}
              className='bg-gray-50 text-primary'
            >
              {
                search.includes("Dashboard") &&
                (<Menu.Item key="1" icon={<PieChartOutlined className='text-primary' />}>
                  <Item name='Dashboard' link='/dashboard' />
                </Menu.Item>)
              }
              {
                search.includes("Activity") && (
                  <Menu.Item key="2" icon={<DesktopOutlined className='text-primary' />}>
                    <Item name="Activity" alert='10' link='/activity' />
                  </Menu.Item>
                )
              }

              {
                search.includes("My tasks") && (
                  <Menu.Item key="3" icon={<ContainerOutlined className='text-primary' />}>
                    <Item name='My tasks' alert='3' link='/task' />
                  </Menu.Item>
                )
              }

              <hr className={`mx-auto mt-2 mb-2 overflow-hidden transition-all ${!collapsed ? 'w-44 ' : 'w-8 '} `} />

              <SubMenu key="sub1" icon={<PieChartOutlined />} title={<Item name='Analytics' />} >
                <Menu.Item key="4">
                  <Item name='My tasks' alert='3' />
                </Menu.Item>
                <Menu.Item key="5">
                  <Item name='My tasks' />
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub2" icon={<AppstoreOutlined />} title={<Item name='Projets' />} >
                <Menu.Item key="6">
                  <Item name='My tasks' alert='3' />
                </Menu.Item>
                <Menu.Item key="7">
                  <Item name='My tasks' />
                </Menu.Item>
              </SubMenu>

              <SubMenu key="sub3" icon={<SettingOutlined />} title={<Item name='Setting' />} >
                <Menu.Item key="8">
                  <Item name='My tasks' alert='3' />
                </Menu.Item>
                <Menu.Item key="9">
                  <Item name='My tasks' />
                </Menu.Item>
              </SubMenu>
            </Menu>
          </li>

        </ul>
        <div className='myfooter'>
          <Menu
            mode="inline"
            theme='light'
            inlineCollapsed={collapsed}
            className='bg-gray-50'
          >
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Item name='Document' link='' />
            </Menu.Item>
          </Menu>

          <Dropdown menu={{ items }} placement="topRight" className={`m-3 p-3`}>
            <div className={` rounded-xl flex leading-4  ${!collapsed ? 'border' : ''}`}>
              <div>

              </div>
              <img src="/logo192.png" alt="" className={`w-10 h-10 rounded-full d  ${collapsed ? 'hidden' : ''} `} />
              <div className={`flex ml-3 text-primary justify-between w-52 items-start  ${collapsed ? 'hidden' : ''}`}>
                <div className='text-left'>
                  <h4 className='font-semibold'>Mia de Silva</h4>
                  <span className='text-xs text-gray-800'>Directeur-CEO Fonder</span>
                </div>
                <span className={` ${collapsed ? 'hidden' : ''}`}>
                  <DownSquareOutlined />
                </span>
              </div>
              <div className={`${!collapsed ? 'hidden' : ''}`}>
                <img src="/logo192.png" alt="" className='w-8 h-7 rounded-full d' />
              </div>
            </div>
          </Dropdown>
        </div>
      </nav>
    </aside >
  );
};

export default Sidebar;